// Тут подключение через npm

import tippy from 'tippy.js';
import Swiper from 'swiper/bundle';
import IMask from 'imask';
import Choices from 'choices.js';
import SimpleBar from 'simplebar';

// Тут подключения вручную



document.addEventListener('DOMContentLoaded', function() {
	// Custom JS

	const surveymanagertoggle = function() {
		const el = document.querySelector('.survet__manager-toggle');

		if (!el) return;

		const managerBlock = document.querySelector('.survey__field_manager')

		el.addEventListener('input', () => {
			managerBlock.classList.toggle('survey__field_hidden', !el.checked)
		})
	}()

	const customScroll = function() {

		Array.prototype.forEach.call(
			document.querySelectorAll('.custom-scroll'),
			(el) => new SimpleBar(el, {
				autoHide: false
			})
		);
	}()

	
	const customSelect = function() {
 
		if (!document.querySelector('.custom-select')) return;
 
		const choices = [];
 
		document.querySelectorAll('.custom-select').forEach((item, index) => {
			let select = item.querySelector('select');
			
			select.setAttribute('data-select', index);
 
			choices[index] = new Choices(select, {
				itemSelectText: '',
				position: 'bottom',
				searchEnabled: true,
				searchChoices: true,
				maxItemCount: 1,
				removeItemButton: false,
				shouldSort: false,
				allowHTML: false,
				placeholder: true,
				searchFields: ['label', 'value'],
				// callbackOnCreateTemplates: () => {
				// 	console.log('asd');
				// 	return 
				// },
			});


			choices[index].passedElement.element.addEventListener(
				'showDropdown',
				function(event) {
					let el = choices[index].passedElement.element.parentElement.parentElement.querySelector('.choices__list--dropdown .choices__list');

					if (el.querySelector('.simplebar-wrapper')) {
						return;
					}

					el.removeAttribute('data-simplebar')

					let dropBar =  new SimpleBar(el, {
						autoHide: false
					})
				},
				false,
			);
		})
	}()

	const casesSlider = function() {
		if (!document.querySelector('.cases__items')) return;

		const swiper = new Swiper('.cases__items .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 8,
			breakpoints: {
				992: {
					spaceBetween: 20
				}
			}
		});
	}()

	const savingSlider = function() {
		if (!document.querySelector('.saving')) return;

		const swiper = new Swiper('.saving__content-slider .swiper', {
			slidesPerView: 1,
			spaceBetween: 8,
			pagination: {
				el: '.saving__content-slider .swiper-pagination',
			},
		});
	}()

	const reviewsSlider = function() {
		if (!document.querySelector('.reviews')) return;

		const swiper = new Swiper('.reviews__items .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 8
		});
	}()
	
	const partnersSlider = function() {
		if (!document.querySelector('.partners')) return;

		const swiper = new Swiper('.partners__slider .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 20,
			navigation: {
				nextEl: '.partners__slider .swiper-button-next',
				prevEl: '.partners__slider .swiper-button-prev',
			},
		});
	}()
	
	const parkSlider = function() {
		if (!document.querySelector('.park')) return;

		const swiper = new Swiper('.park__slider .swiper', {
			slidesPerView: 'auto',
			spaceBetween: 8
		});
	}()

	const tooltip = function() {
		if (!document.querySelector('.tooltip')) return;

		tippy('.tooltip', {
			trigger: 'mouseenter focus click', // Триггеры для отображения тултипа
			animation: 'scale', // Анимация
			duration: [300, 250], // Продолжительность анимации (открытие и закрытие)
			theme: 'light' // Тема
		});
	}()

	const faq = function() {
		const items = document.querySelectorAll('.faq__item');

		if (!items.length) return;

		let activeItem = null;

		function removeActive(item) {
			if (!item) return;

			let content = item.querySelector('.faq__item-content');

			content.style.maxHeight = content.scrollHeight + 'px';
			item.classList.remove('faq__item_active');

			setTimeout(() => {
				content.style.maxHeight = '';
			}, 40);
		}

		items.forEach(item => {
			item.addEventListener('click', e => {
				let content = item.querySelector('.faq__item-content');


				if (item.classList.contains('faq__item_active')) {
					removeActive(activeItem)
				}
				else {
					if (item != activeItem) removeActive(activeItem)

					content.style.maxHeight = content.scrollHeight + 'px';
					item.classList.add('faq__item_active')

					setTimeout(() => {
						item.querySelector('.faq__item-content').style.maxHeight = '';
					}, 300);
				}

				activeItem = item;
			})
		})
	}()

	const quizSticky = function() {
		const el = document.querySelector('.quiz__form-controls');

		if (!el) return;

		const wrapper = document.querySelector('.quiz__form-controls-wrapper');
		const quizSection = document.querySelector('.quiz');
		const form = document.querySelector('.quiz__form');
		let formIsActive = false;

		function action() {
			let topLimit = quizSection.getBoundingClientRect().top - window.innerHeight + el.offsetHeight;
			let bottomLimit = wrapper.getBoundingClientRect().top - window.innerHeight + el.offsetHeight;

			if (((topLimit < 0) && (bottomLimit > 0)) && formIsActive) {
				el.style.position = 'fixed';

				if (window.innerWidth < 992) {
					el.style.bottom = '0';
					el.style.left = '0';
					el.style.width = '100%';
				}
				else {
					el.style.bottom = '20px';
					const rect = wrapper.getBoundingClientRect();
					const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
					const positionLeft = rect.left + scrollLeft;
					el.style.left = positionLeft + 'px';
					el.style.width = wrapper.offsetWidth + 'px';
				}
			}
			else {
				el.style.position = '';
				el.style.bottom = '';
				el.style.left = '';
				el.style.width = '';
			}
		}

		action();

		window.addEventListener('scroll', function() {
			action()
		});

		function isActiveForm() {
			let isActive = false;

			form.querySelectorAll('input').forEach(input => {
				switch (input.getAttribute('type')) {
					case 'tel':
						if (input.value.trim() !== '') {
							isActive = true;
							return true;
						}
						break;
					case 'radio':
						if (input.checked) {
							isActive = true;
							return true;
						}
						break;
				
					default:
						break;
				}
			})

			return isActive;
		}

		form.querySelectorAll('input').forEach(input => {
			input.addEventListener('input', e => {
				formIsActive = isActiveForm();
				action();
			})
		})
	}()
	
	const customModal = function() {
		const closeModals = function() {
			document.querySelectorAll('.modal').forEach(element => {
				element.classList.remove('modal_active');
			});
		}
		
		const openModal = function(modal) {
			closeModals();
			const modalElement = document.querySelector('#' + modal);
			modalElement.classList.add('modal_active');

			if (document.querySelector('.modal_active')) {
				
			}
		}
		
		window.openModal = openModal;
		window.closeModals = closeModals;
		
		document.querySelectorAll('[data-open-modal]').forEach(element => {
			element.addEventListener('click', (e) => {
				e.preventDefault();
				openModal(e.currentTarget.getAttribute('data-open-modal'))
			})
		});
		
		const close = document.querySelectorAll('[data-close-modal]');
		if (close) {
			close.forEach(function(el){
				el.addEventListener('click', (e) => {
					closeModals();
				})
			})
		}
	}()


	const smoothScroll = function() {
		const els = document.querySelectorAll('.smooth-scroll');

		if (!els.length) return;

		els.forEach(el => {
			el.addEventListener('click', () => {
				document.querySelector(el.getAttribute('data-to')).scrollIntoView({
					behavior: 'smooth'
				});
			})
		})
	}()

	const stickyHeader = function() {
		const header = document.querySelector('.header');

		if (!header) return;

		let lastScrollTop = 0;

		function action(e) {
			if (window.innerWidth < 992) {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

				if (scrollTop == 0) {
					header.style.position = '';
					header.style.top = '';
					header.classList.remove('header_sticky');

					return;
				}

				if (scrollTop > lastScrollTop) {
					if (header.classList.contains('header_sticky')) {
						header.style.position = 'absolute';
						header.style.top = scrollTop + 'px';
						header.classList.remove('header_sticky');
					}
				} else {
					if (!header.classList.contains('header_sticky')) {
						header.classList.add('header_sticky');
						header.style.top = scrollTop - header.offsetHeight + 'px';
					}
					else {
						if (header.getBoundingClientRect().top > 0) {
							header.style.position = 'fixed';
							header.style.top = 0;
						}
					}
				}


				lastScrollTop = scrollTop;
			}
			else {
				header.style.position = '';
				header.style.top = '';
				header.classList.remove('header_sticky');
			}
		}

		action();

		window.addEventListener('scroll', function(e) {
			action(e)
		});

	}()

	const phonemask = function() {
		var selector = document.querySelectorAll(".phone-mask");
 
		if (!selector.length) return

		const maskOptions = {
			mask: "+7 (000) 000-00-00",
    lazy: false,
		};
		
		selector.forEach(item => {
			IMask(item, maskOptions);
		})
	}()

	const headerMenu = function() {
		var itemsWithChild = document.querySelectorAll(".header__menu-item_has-child");
 
		if (!itemsWithChild.length) return

		function closeChildMenu() {
			document.querySelector('.header__menu-item_child-open')?.classList.remove('header__menu-item_child-open')
			document.querySelector('.header__child-menu_active')?.classList.remove('header__child-menu_active')
		}

		let activeItem = null;

		itemsWithChild.forEach(item => {
			item.addEventListener('click', () => {
				if (item != activeItem) {
					closeChildMenu();
				}

				activeItem = item;
				item.classList.toggle('header__menu-item_child-open')
				document.querySelector(item.getAttribute('data-for')).classList.toggle('header__child-menu_active')
			})
		})

		window.addEventListener('scroll', function(e) {
			closeChildMenu()
		});

		const overlays = document.querySelectorAll('.header__child-menu-overlay')

		overlays.forEach(overlay => {
			overlay.addEventListener('click', () => {
				closeChildMenu()
			})
		})
	}()

	const fixedMenuItems = function() {
		var itemsWithChild = document.querySelectorAll(".fixed-menu__menu-item_has-child");
 
		if (!itemsWithChild.length) return

		function closeChildMenu() {
			document.querySelector('.fixed-menu__menu-item_child-open')?.classList.remove('fixed-menu__menu-item_child-open')
			document.querySelector('.fixed-menu__child-menu_active')?.classList.remove('fixed-menu__child-menu_active')
		}

		let activeItem = null;

		itemsWithChild.forEach(item => {
			item.addEventListener('click', () => {
				if (item != activeItem) {
					closeChildMenu();
				}

				activeItem = item;

				let childMenu = item.querySelector('.fixed-menu__menu-child');

				childMenu.style.maxHeight = childMenu.scrollHeight + 'px';
				item.classList.toggle('fixed-menu__menu-item_child-open')

				if (item !== activeItem) {
					setTimeout(() => {
						childMenu.style.maxHeight = '';
					}, 300);

					activeItem = item;
				} else {
					setTimeout(() => {
						childMenu.style.maxHeight = '';
					}, 50);

					activeItem = null;
				}

			})
		})
	}()

	const menu = function() {
		document.querySelector('.btn-burger').addEventListener('click', () => {
			document.querySelector('.body').classList.toggle('open-menu');
		})
	}()

	const quizExact = function() {
		let btn = document.querySelector('.quiz__exact-btn');

		if (!btn) return;

		btn.addEventListener('click', () => {
			let parent = document.querySelector('.quiz__exact');
			let dropdown = parent.querySelector('.quiz__exact-dropdown');

			dropdown.style.maxHeight = dropdown.scrollHeight + 'px';

			parent.classList.toggle('quiz__exact_active');

			setTimeout(() => {
				dropdown.style.maxHeight = '';
			}, 300);
		})
	}()


	const fixedBottom = function() {
		let el = document.querySelector('.fixed-bottom');

		if (!el) return;

		let banner = document.querySelector('.banner')

		function action(e) {
			el.classList.toggle('fixed-bottom_active', banner.getBoundingClientRect().top + banner.offsetHeight < 0)
		}

		action();

		window.addEventListener('scroll', function(e) {
			action(e)
		});

	}()


	const savingTab = function() {
		let els = document.querySelectorAll('.saving__tab');

		if (!els.length) return;

		let parent = els[0].parentElement;
		let activeEl = parent.querySelector('.saving__tab_active');

		els.forEach(el => {
			el.addEventListener('click', () => {
				activeEl.classList.remove('saving__tab_active');
				el.classList.add('saving__tab_active');

				document.querySelector(activeEl.getAttribute('data-for')).classList.remove('saving__content_active');
				document.querySelector(el.getAttribute('data-for')).classList.add('saving__content_active');

				activeEl = el;
			})
		})

	}()

	const footerMenuItems = function() {
		var itemsWithChild = document.querySelectorAll(".footer__menu-item_has-child");
 
		if (!itemsWithChild.length) return

		function closeChildMenu() {
			document.querySelector('.footer__menu-item_child-open')?.classList.remove('footer__menu-item_child-open')
			// document.querySelector('.fixed-menu__child-menu_active')?.classList.remove('fixed-menu__child-menu_active')
		}

		let activeItem = null;

		itemsWithChild.forEach(item => {
			item.addEventListener('click', () => {
				if (item != activeItem) {
					closeChildMenu();
				}

				let childMenu = item.querySelector('.footer__menu-child');

				childMenu.style.maxHeight = childMenu.scrollHeight + 'px';
				item.classList.toggle('footer__menu-item_child-open')

				if (item !== activeItem) {
					setTimeout(() => {
						childMenu.style.maxHeight = '';
					}, 300);

					activeItem = item;
				} else {
					setTimeout(() => {
						childMenu.style.maxHeight = '';
					}, 50);

					activeItem = null;
				}

			})
		})
	}()

	const quizActions = function() {
		const quiz = document.querySelector('.quiz');

		if (!quiz) return;
		
		const moscowDistricts = {
			"SZAO": { // Северо-Западный административный округ
				coordinates: [55.831, 37.399],
				areas: [
					{ name: "Строгино", coordinates: [55.8039, 37.4146], tows: 1 },
					{ name: "Щукино", coordinates: [55.8102, 37.4654], tows: 2 },
					{ name: "Тушино Северное", coordinates: [55.8509, 37.4380], tows: 5 },
					{ name: "Тушино Южное", coordinates: [55.8284, 37.4379], tows: 3 },
					{ name: "Митино", coordinates: [55.8467, 37.3623], tows: 3 },
					{ name: "Покровское-Стрешнево", coordinates: [55.8185, 37.4355], tows: 3 },
					{ name: "Северное", coordinates: [55.8622, 37.4280], tows: 3 }
				]
			},
			"SAO": { // Северный административный округ
				coordinates: [55.8607, 37.5245],
				areas: [
					{ name: "Беговой", coordinates: [55.7906, 37.5608] },
					{ name: "Савеловский", coordinates: [55.7992, 37.5885] },
					{ name: "Аэропорт", coordinates: [55.8008, 37.5311] },
					{ name: "Дмитровский", coordinates: [55.8831, 37.5663] },
					{ name: "Головинский", coordinates: [55.8600, 37.5221] },
					{ name: "Тимирязевский", coordinates: [55.8387, 37.5754] },
					{ name: "Ховрино", coordinates: [55.8775, 37.4913] },
					{ name: "Левобережный", coordinates: [55.8748, 37.4741] },
					{ name: "Войковский", coordinates: [55.8204, 37.4977] },
					{ name: "Западное Дегунино", coordinates: [55.8786, 37.5329] },
					{ name: "Коптево", coordinates: [55.8363, 37.5123] },
					{ name: "Сокол", coordinates: [55.8042, 37.5151] },
					{ name: "Молжаниновский", coordinates: [55.9234, 37.4179] }
				]
			},
			"SVAO": { // Северо-Восточный административный округ
				coordinates: [55.8701, 37.6794],
				areas: [
					{ name: "Алексеевский", coordinates: [55.8192, 37.6384] },
					{ name: "Алтуфьевский", coordinates: [55.8974, 37.5854] },
					{ name: "Бабушкинский", coordinates: [55.8706, 37.6805] },
					{ name: "Бибирево", coordinates: [55.8834, 37.6054] },
					{ name: "Бутырский", coordinates: [55.8197, 37.5820] },
					{ name: "Лианозово", coordinates: [55.8978, 37.5854] },
					{ name: "Лосиноостровский", coordinates: [55.8695, 37.7191] },
					{ name: "Марфино", coordinates: [55.8365, 37.6167] },
					{ name: "Марьина Роща", coordinates: [55.7955, 37.6114] },
					{ name: "Останкинский", coordinates: [55.8198, 37.6112] },
					{ name: "Отрадное", coordinates: [55.8591, 37.6054] },
					{ name: "Ростокино", coordinates: [55.8388, 37.6818] },
					{ name: "Свиблово", coordinates: [55.8575, 37.6538] },
					{ name: "Северное Медведково", coordinates: [55.8838, 37.6614] },
					{ name: "Южное Медведково", coordinates: [55.8683, 37.6523] },
					{ name: "Ярославский", coordinates: [55.8758, 37.7432] }
				]
			},
			"ZAO": { // Западный административный округ
				coordinates: [55.7301, 37.4517],
				areas: [
					{ name: "Дорогомилово", coordinates: [55.7405, 37.5624] },
					{ name: "Филевский Парк", coordinates: [55.7464, 37.4783] },
					{ name: "Фили-Давыдково", coordinates: [55.7374, 37.4804] },
					{ name: "Крылатское", coordinates: [55.7566, 37.4146] },
					{ name: "Кунцево", coordinates: [55.7432, 37.4163] },
					{ name: "Можайский", coordinates: [55.7153, 37.4608] },
					{ name: "Новопеределкино", coordinates: [55.6450, 37.3915] },
					{ name: "Очаково-Матвеевское", coordinates: [55.7063, 37.4464] },
					{ name: "Проспект Вернадского", coordinates: [55.6767, 37.5046] },
					{ name: "Раменки", coordinates: [55.6989, 37.4846] },
					{ name: "Солнцево", coordinates: [55.6451, 37.3911] },
					{ name: "Тропарево-Никулино", coordinates: [55.6698, 37.4711] }
				]
			},
			"CAO": { // Центральный административный округ
				coordinates: [55.7522, 37.6156],
				areas: [
					{ name: "Арбат", coordinates: [55.7522, 37.5875] },
					{ name: "Басманный", coordinates: [55.7599, 37.6447] },
					{ name: "Замоскворечье", coordinates: [55.7339, 37.6246] },
					{ name: "Красносельский", coordinates: [55.7710, 37.6680] },
					{ name: "Мещанский", coordinates: [55.7746, 37.6314] },
					{ name: "Пресненский", coordinates: [55.7601, 37.5768] },
					{ name: "Таганский", coordinates: [55.7395, 37.6663] },
					{ name: "Тверской", coordinates: [55.7651, 37.6065] },
					{ name: "Хамовники", coordinates: [55.7282, 37.5842] },
					{ name: "Якиманка", coordinates: [55.7334, 37.6184] }
				]
			},
			"VAO": { // Восточный административный округ
				coordinates: [55.7724, 37.8418],
				areas: [
					{ name: "Богородское", coordinates: [55.8130, 37.7229] },
					{ name: "Вешняки", coordinates: [55.7376, 37.8180] },
					{ name: "Восточный", coordinates: [55.8025, 37.8445] },
					{ name: "Гольяново", coordinates: [55.8094, 37.8186] },
					{ name: "Ивановское", coordinates: [55.7464, 37.8085] },
					{ name: "Измайлово", coordinates: [55.7876, 37.7985] },
					{ name: "Косино-Ухтомский", coordinates: [55.7028, 37.8600] },
					{ name: "Метрогородок", coordinates: [55.8233, 37.7457] },
					{ name: "Новогиреево", coordinates: [55.7522, 37.8140] },
					{ name: "Новокосино", coordinates: [55.7424, 37.8604] },
					{ name: "Перово", coordinates: [55.7501, 37.7847] },
					{ name: "Преображенское", coordinates: [55.7951, 37.7168] },
					{ name: "Северное Измайлово", coordinates: [55.8007, 37.8067] },
					{ name: "Соколиная Гора", coordinates: [55.7715, 37.7286] },
					{ name: "Сокольники", coordinates: [55.7945, 37.6795] }
				]
			},
			"YUZAO": { // Юго-Западный административный округ
				coordinates: [55.6189, 37.5226],
				areas: [
					{ name: "Академический", coordinates: [55.7061, 37.5731] },
					{ name: "Гагаринский", coordinates: [55.7054, 37.5651] },
					{ name: "Зюзино", coordinates: [55.6714, 37.5754] },
					{ name: "Коньково", coordinates: [55.6376, 37.5202] },
					{ name: "Котловка", coordinates: [55.6776, 37.5876] },
					{ name: "Ломоносовский", coordinates: [55.7058, 37.5734] },
					{ name: "Обручевский", coordinates: [55.6600, 37.5277] },
					{ name: "Северное Бутово", coordinates: [55.5701, 37.5655] },
					{ name: "Теплый Стан", coordinates: [55.6149, 37.5068] },
					{ name: "Черемушки", coordinates: [55.6714, 37.5540] },
					{ name: "Южное Бутово", coordinates: [55.5379, 37.5267] },
					{ name: "Ясенево", coordinates: [55.6068, 37.5197] }
				]
			},
			"YUAO": { // Южный административный округ
				coordinates: [55.6389, 37.7276],
				areas: [
					{ name: "Бирюлево Восточное", coordinates: [55.5832, 37.6674] },
					{ name: "Бирюлево Западное", coordinates: [55.5684, 37.6481] },
					{ name: "Братеево", coordinates: [55.6205, 37.7569] },
					{ name: "Даниловский", coordinates: [55.7083, 37.6246] },
					{ name: "Донской", coordinates: [55.7081, 37.6101] },
					{ name: "Зябликово", coordinates: [55.6100, 37.7440] },
					{ name: "Москворечье-Сабурово", coordinates: [55.6391, 37.6678] },
					{ name: "Нагатино-Садовники", coordinates: [55.6832, 37.6362] },
					{ name: "Нагатинский Затон", coordinates: [55.6823, 37.6517] },
					{ name: "Нагорный", coordinates: [55.6546, 37.6159] },
					{ name: "Орехово-Борисово Северное", coordinates: [55.6106, 37.7275] },
					{ name: "Орехово-Борисово Южное", coordinates: [55.6107, 37.7280] },
					{ name: "Царицыно", coordinates: [55.6147, 37.6698] },
					{ name: "Чертаново Северное", coordinates: [55.6126, 37.6069] },
					{ name: "Чертаново Центральное", coordinates: [55.6123, 37.5960] },
					{ name: "Чертаново Южное", coordinates: [55.5922, 37.5973] }
				]
			},
			"YUVAO": { // Юго-Восточный административный округ
				coordinates: [55.6765, 37.7536],
				areas: [
					{ name: "Выхино-Жулебино", coordinates: [55.7153, 37.7917] },
					{ name: "Капотня", coordinates: [55.6326, 37.8046] },
					{ name: "Кузьминки", coordinates: [55.7050, 37.7508] },
					{ name: "Лефортово", coordinates: [55.7581, 37.7010] },
					{ name: "Люблино", coordinates: [55.6759, 37.7430] },
					{ name: "Марьино", coordinates: [55.6504, 37.7435] },
					{ name: "Некрасовка", coordinates: [55.6838, 37.9057] },
					{ name: "Нижегородский", coordinates: [55.7462, 37.7302] },
					{ name: "Печатники", coordinates: [55.6928, 37.7280] },
					{ name: "Рязанский", coordinates: [55.7173, 37.7932] },
					{ name: "Текстильщики", coordinates: [55.7021, 37.7381] },
					{ name: "Южнопортовый", coordinates: [55.7233, 37.6830] }
				]
			},
			"NaMKAD": { // На МКАД
				coordinates: [55.755864, 37.617698], // Примерные координаты для всей территории на МКАД
				areas: []
			},
			"ZaMKAD": { // За МКАД
				coordinates: [55.755864, 37.617698], // Примерные координаты для всей территории за МКАД
				areas: []
			}
		};

		let activeDistrictName = null;
		let activeDistrict = null;
		let activeArea = null;
		let map = null;
		let zoom = 8;
		const areaBlock = quiz.querySelector('.quiz__area');
		const exactBlock = quiz.querySelector('.quiz__exact');
		const areasContainer = quiz.querySelector('.quiz__area-items');
		const choicedAreaInfo = quiz.querySelector('.quiz__form-choiced-area-info');
		const choicedAreaChange = quiz.querySelector('.quiz__form-choiced-area-change')

		ymaps.ready(init);
		
		function init() {
			map = new ymaps.Map("map", {
				center: [55.755864, 37.617698],
				zoom: zoom,
				controls: [] 
			});
		}

		quiz.querySelectorAll('input[name="district"]').forEach(district => {
			district.addEventListener('input', () => {
				if (district.checked) {
					choicedAreaInfo.classList.remove('quiz__form-choiced-area-info_active');
					if (activeArea) activeArea.checked = false;
					activeArea = null;

					activeDistrictName = district.value;
					activeDistrict = moscowDistricts[activeDistrictName];

					map.panTo(activeDistrict.coordinates)
						.then(() => {
							if ((activeDistrictName == 'NaMKAD') || (activeDistrictName == 'ZaMKAD')) {
								zoom = 8;
							}
							else {
								zoom = 10;
							}
		
							map.setZoom(zoom, {
								smooth: true,
								duration: 500
							})
						});
					
					areaBlock.classList.add('quiz__area_visible')
					exactBlock.classList.add('quiz__exact_visible')

					areasContainer.innerHTML = '';

					activeDistrict.areas.forEach(area => {
						areasContainer.innerHTML += `
							<label class="quiz__area-item ${(area.name.length > 12) ?'quiz__area-item_many-words':''} checker">
								<input class="checker__input" type="radio" name="area" value="${area.name}" data-lat="${area.coordinates[0]}" data-lng="${area.coordinates[1]}" data-tows=${area.tows?area.tows:0}>
								<span class="checker__label">
									${area.name}
								</span>
							</label>
						`;
					})

					quiz.querySelectorAll('input[name="area"]').forEach(area => {
						area.addEventListener('input', () => {
							if (area.checked) {
								activeArea = area;
								let coord = [parseFloat(area.getAttribute('data-lat')), parseFloat(area.getAttribute('data-lng'))]
								
								map.panTo(coord)
									.then(() => {
										zoom = 13;
					
										map.setZoom(zoom, {
											smooth: true,
											duration: 500
										})
									})
									.then(() => {
										choicedAreaInfo.classList.add('quiz__form-choiced-area-info_active');
										choicedAreaInfo.querySelector('.quiz__form-choiced-area-value').textContent = area.value;
										choicedAreaInfo.querySelector('.quiz__form-choiced-area-tows-count').textContent = area.getAttribute('data-tows');
									})
							}
						})
					})

				}
			})
		})

		choicedAreaChange.addEventListener('click', () => {
			choicedAreaInfo.classList.remove('quiz__form-choiced-area-info_active');
			activeArea.checked = false;
			activeArea = null;
		})
			
	}()


	const contacts = function() {
		const el = document.querySelector('.contacts__map');

		if (!el) return;

		ymaps.ready(init);
		
		function init() {
			const map = new ymaps.Map("map", {
				center: [55.721027, 37.666169],
				zoom: 17,
				controls: [] 
			});

			const placemark = new ymaps.Placemark([55.721027, 37.666169], {}, {
				iconLayout: 'default#image',
				iconImageHref: 'img/icon-pin-dark.svg',
				iconImageSize: [40, 40],
				iconImageOffset: [-20, -20]
			});
			
			map.geoObjects.add(placemark);
		}
	}()
})